.App {
  text-align: center;
  font-family: alwyn-new-web !important;
}

* {
  font-family: alwyn-new-web !important;
}

.MuiTooltip-tooltip {
  font-size: 0.9rem !important;
}

.MuiTypography-body1 {
  font-size: 1.1rem !important;
}

.apexcharts-legend {
  align-items: end;
}