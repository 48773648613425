/*.top, .middle-left, .middle-right, .bottom-left, .bottom-right, .score {*/
/*    position: absolute;*/
/*    padding: 16px;*/
/*    cursor: pointer;*/
/*    user-select: none;*/
/*    opacity: 0.8;*/
/*}*/

/*.top {*/
/*    transform: translate(10%,0%);*/
/*}*/

/*.middle-right {*/
/*    transform: translate(100%,75%);*/
/*}*/

/*.middle-left {*/
/*    transform: translate(-80%,75%);*/
/*}*/

/*.bottom-left {*/
/*    transform: translate(-45%,170%);*/
/*}*/

/*.bottom-right {*/
/*    transform: translate(60%,170%);*/
/*}*/

/*.score {*/
/*    transform: translate(13%, 190%);*/
/*    background: #ebf2ee;*/
/*    border-radius: 90%*/
/*}*/

/*!*.icon:hover  .cls-2, .icon:hover  .cls-3, .icon:hover  .cls-4, .icon:hover  .cls-5, .icon:hover  .cls-6*!*/
/*.icon:hover  .cls-5 {*/
/*    transform-origin: 50% 50%;*/
/*    animation: spin 10s infinite linear;*/
/*}*/

/*.icon {*/
/*    width: 200px;*/
/*}*/

/*@keyframes spin {*/
/*    0% { transform: rotate(0deg); }*/
/*    100% { transform: rotate(360deg); }*/
/*}*/

.card {
    transition: 0.4s;
    box-shadow: none;
    /*border-radius: 10%;*/
}

.card:hover {
    transition: 0.4s;
    transform: scale(1.03);
    box-shadow: none;
    /*border-radius: 10%;*/
    /*box-shadow: 0 6px 12px 0 grey;*/
}