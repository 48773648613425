
.back_to_category {
    width: 30px;
    height: 30px;
    margin: 2px;
    border-radius: 7px;
    border-style: solid;
    border-width: 2px;
    border-color: white;
}

.flex-text {
    text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}

.chip {
    /*display: inline-flex;*/
    border-radius: 50px;
    padding: 10px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    background-color: transparent;
    cursor: pointer;
    justify-content: center;
    position: relative;
    margin: 10px 0;
    color: grey;
    /*font-size: 1rem;*/
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    line-height: 1.6;
    letter-spacing: 0.0075em;
}

.chip[wrong = "true"] {
    color: white !important;
    background-color: darkred !important;
    /*animation: wobble-hor-top 1s ease-in-out alternate;*/
    animation:move-horizontally 0.5s ease-in-out both
}

@keyframes move-horizontally{
    0%,100% {
        transform: translateX(0)
    }
    30% {
        transform: translateX(-10px)
    }
    60% {
        transform: translateX(10px)
    }
}

.chip[correct = "true"] {
    background-color: green !important;
    color: white !important;
    animation:heartbeat 1s
}

@keyframes heartbeat {
    from {
        transform: scale(1);
        transform-origin: center center;
        animation-timing-function: ease-out
    }
    33% {
        transform: scale(.96);
        animation-timing-function: ease-in
    }
    45% {
        transform: scale(1);
        animation-timing-function: ease-out
    }
}

.chip:active {
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0 1px 1px 0 rgba(0,0,0,0.14), 0 1px 3px 0 rgba(0,0,0,0.12);
    border-color: transparent;
}

.chip:hover {
    box-sizing: border-box;
    background-color: rgba(0, 0, 0, 0.06);
    transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.text-span {
    padding-left: 12px;
    padding-right: 12px;
    text-align: center;
    /*color: gray;*/
    /*font-size: 1.5rem !important;*/
    /*cursor: pointer;*/
    /*user-select: none;*/
    /*-webkit-tap-highlight-color: transparent;*/
    /*text-align: left;*/
    /*font-size: 1.1rem;*/
}

.ripple-effect {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    user-select: none;
    overflow: hidden;
    position: absolute;
    border-radius: inherit;
    pointer-events: none;
}

.flipOut[flip="true"] {
    animation: flipHorizontal .5s cubic-bezier(.55,.085,.68,.53) both
}

.flipIn[flip="true"] {
    padding: 20px;
    animation: flipHorizontal 1s cubic-bezier(.55,.085,.68,.53) both
}

@keyframes flipHorizontal {
    0% {
        transform: rotateX(50deg);
        opacity: 0
    }
    100% {
        transform: rotateX(0);
        opacity: 1
    }
}


.quizContainer {
    animation: bounce 1.1s both;
    opacity: 1;
    filter: saturate(40%);
}

@keyframes bounce {
    0% {
        transform: translateY(-200px);
        animation-timing-function: ease-in;
        opacity: 0
    }
    /*38% {*/
    /*    transform: translateY(0);*/
    /*    animation-timing-function: ease-out;*/
    /*    opacity: 1*/
    /*}*/
    10% {
        transform: translateY(-65px);
        animation-timing-function: ease-in
    }
    20% {
        transform: translateY(0);
        animation-timing-function: ease-out;
        opacity: 1
    }
}

.answerImage {
    width: 100%;
    border-radius: 5px
}

.answerImage_desktop {
    width: 50%;
    border-radius: 5px
}