
.categories {
    animation: fade-in 2s cubic-bezier(.39,.575,.565,1.000) both
}

@keyframes fade-in {
    0% {
        opacity: 0
    }
    100% {
        opacity: 1
    }
}