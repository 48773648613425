.help {
    transform: translate(0, 5px);
    cursor: pointer;
}

.help[beat="true"] {
    animation: jiggle 8s infinite both
}

@keyframes jiggle {
    0%, 100% {
        transform: translateY(0);
        transform-origin: 50% 50%
    }
    15% {
        transform:translateY(-2px)
    }
    30% {
        transform:translateY(2px)
    }
    45% {
        transform:translateY(-2px)
    }
    60% {
        transform:translateY(1px)
    }
    75% {
        transform:translateY(0px)
    }
}